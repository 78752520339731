import * as React from 'react';
import { IconProps } from '../icon.types';

export const LotusIcon: React.FC<IconProps> = ({
  color = '#fff',
  width,
  height,
  size = 24,
  ...props
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23.156 11.559a1.1 1.1 0 0 0-.69-.525 7.3 7.3 0 0 0-3-.096c.426-1.849.152-3.305-.123-4.153a1.12 1.12 0 0 0-1.304-.752 7.5 7.5 0 0 0-2.692 1.195 8.5 8.5 0 0 0-2.672-3.476 1.12 1.12 0 0 0-1.352 0A8.5 8.5 0 0 0 8.65 7.228a7.5 7.5 0 0 0-2.69-1.195 1.12 1.12 0 0 0-1.304.753c-.276.847-.549 2.303-.123 4.152a7.3 7.3 0 0 0-3 .096 1.12 1.12 0 0 0-.8 1.386c.317 1.131 1.29 3.284 4.246 4.991 2.969 1.714 5.603 1.715 7.02 1.715H12c1.417 0 4.051 0 7.02-1.715 2.957-1.707 3.929-3.86 4.245-4.99a1.1 1.1 0 0 0-.109-.862m-4.96-4.793a.375.375 0 0 1 .434.251c.273.84.54 2.343-.03 4.27a10.2 10.2 0 0 1-.952 2.181 12.05 12.05 0 0 1-3.382 3.698c.947-1.247 1.858-3.161 1.858-5.915a10.5 10.5 0 0 0-.504-3.297 6.8 6.8 0 0 1 2.575-1.188m-6.424-2.413a.376.376 0 0 1 .454 0c.946.706 3.148 2.793 3.148 6.898 0 2.161-.596 4.024-1.773 5.535a7.1 7.1 0 0 1-1.6 1.523 7.1 7.1 0 0 1-1.603-1.523c-1.176-1.512-1.772-3.374-1.772-5.535 0-4.105 2.201-6.192 3.148-6.898M5.368 7.017a.375.375 0 0 1 .435-.25c.932.21 1.81.614 2.575 1.187a10.5 10.5 0 0 0-.504 3.297c0 2.754.911 4.668 1.858 5.915l-.234-.172a12 12 0 0 1-3.147-3.527 10.2 10.2 0 0 1-.952-2.18c-.572-1.927-.304-3.43-.031-4.27m-3.913 5.201a.37.37 0 0 1 .264-.458c.652-.166 1.696-.298 3.036-.017.241.731.558 1.435.946 2.1 1.208 2.09 2.968 3.595 4.392 4.457a11.65 11.65 0 0 1-4.74-1.538c-2.726-1.574-3.613-3.523-3.898-4.544m21.088 0c-.285 1.021-1.172 2.97-3.898 4.544a11.65 11.65 0 0 1-4.74 1.538c1.424-.862 3.184-2.366 4.392-4.458.387-.664.704-1.368.945-2.099 1.34-.281 2.385-.15 3.037.017a.37.37 0 0 1 .264.458'
      fill={color}
    />
  </svg>
);
