import { FC, useState } from 'react';
import { ModalProps } from './modal.types';
import ReactDOM from 'react-dom';
import { StyledModal, ModalContent, ModalClose } from './Modal.styled';
import { CloseIcon } from 'shared/icon';

export const Modal: FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  types,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onRequestClose(); 
    }, 300); 
  };
  
  if (!isOpen && !isClosing) return null;

  return ReactDOM.createPortal(
    <StyledModal isClosing={isClosing}>
      <ModalContent types={types}>
        <ModalClose onClick={handleClose} >
          <CloseIcon/>
        </ModalClose>
        {children}
      </ModalContent>
    </StyledModal>,
    document.body
  );
};
