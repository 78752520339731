import styled, { keyframes }  from 'styled-components';
import { ModalProps } from './modal.types'

const fadeIn = keyframes`
  0% {
    opacity: 0; 
  }
  100% {
    opacity: 1; 
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1; 
  }
  100% {
    opacity: 0; 
  }
`;

export const StyledModal = styled.div<Pick<ModalProps, 'isClosing'>>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s ease-out;
  background: #0000004C;
`;

export const ModalContent = styled.div<Pick<ModalProps, 'types'>>`
background: white;
width: 100%;
padding: 55px 0;
position: relative;
background: 
${(props) =>
  props.types === 'success' ? 'rgba(0, 128, 0)' : 
  props.types === 'error' ? 'rgba(255, 0, 0)' : 
  props.types === 'warn' ? 'rgba(255, 255, 0)' : 
  'linear-gradient(to bottom, #433581 0%, #02030B 100%)'};
`;

export const ModalClose = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`