import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { ROUTES } from './routes';

import { ProtectedRoute } from 'feature/auth';

import { Layout } from 'shared/layout';

import { Menu } from 'widget/menu';

import { LoginPage } from 'pages/Login';

import { SandBox } from 'pages/SandBox';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <Layout>
            <LoginPage />
          </Layout>
        ),
      },
      {
        path: ROUTES.APP,
        element: (
          <ProtectedRoute>
            <Layout>
              <Outlet />
            </Layout>
            <Menu />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ROUTES.GAME,
            element: <SandBox />,
          },
          {
            path: ROUTES.TASKS,
            element: <div>tasks</div>,
          },
          {
            path: ROUTES.RATINGS,
            element: <div>ratings</div>,
          },
          {
            path: ROUTES.PROFILE,
            element: <div>profile</div>,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.LOGIN} />, // Перенаправляем на логин для несуществующих маршрутов
  },
]);
