export const colors = {
  primaryColor: '#7f64f2',
  secondaryColor: '#d8f34f',
  tertiaryColor: '#271f4e',
  textInactiveColor: '#808084',
  blackColor: '#02030b',
  whiteColor: '#fff',
  gradient: 'linear-gradient(180deg, #433581 0%, #02030B 61.47%)', 
  progressBgColor: '#D8F34F40',    
  fillBarColor: '#D8F34F',
};
