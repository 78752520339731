import { Fonts } from 'app/Fonts';
import { store } from 'app/store';

import { Model3dProvider } from 'feature/3d';
import { AuthProvider } from 'feature/auth';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { ToastProvider } from 'shared/toast/ToastProvider';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import { ModalProvider } from 'shared/modal';
import GlobalStyle from 'app/GlobalStyles';

interface AppProvidersProps {
  children: ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  Fonts();
  return (
    <Provider store={store}>
      <ModalProvider>
        <GlobalStyle />
        <ToastProvider>
          <ToastContainer />

          <Model3dProvider>
            <AuthProvider>{children}</AuthProvider>
          </Model3dProvider>
        </ToastProvider>
      </ModalProvider>
    </Provider>
  );
};
