import * as React from 'react';
import { IconProps } from '../icon.types';

export const TasksIcon: React.FC<IconProps> = ({
  color = '#fff',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.25 4.125H3.75A1.126 1.126 0 0 0 2.625 5.25v13.5a1.126 1.126 0 0 0 1.125 1.125h16.5a1.126 1.126 0 0 0 1.125-1.125V5.25a1.126 1.126 0 0 0-1.125-1.125m.375 14.625a.375.375 0 0 1-.375.375H3.75a.375.375 0 0 1-.375-.375V5.25a.375.375 0 0 1 .375-.375h16.5a.376.376 0 0 1 .375.375zM17.25 9a.375.375 0 0 1-.375.375h-9.75a.375.375 0 0 1 0-.75h9.75A.375.375 0 0 1 17.25 9m0 3a.375.375 0 0 1-.375.375h-9.75a.375.375 0 1 1 0-.75h9.75a.375.375 0 0 1 .375.375m0 3a.375.375 0 0 1-.375.375h-9.75a.375.375 0 1 1 0-.75h9.75a.375.375 0 0 1 .375.375'
      fill={color}
    />
  </svg>
);
