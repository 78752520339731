import * as React from 'react';
import { IconProps } from '../icon.types';

export const ProfileIcon: React.FC<IconProps> = ({
  color = '#fff',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.497 14.885a6 6 0 1 0-4.743 0 10.86 10.86 0 0 0-5.96 3.623.375.375 0 0 0 .575.482 10.125 10.125 0 0 1 15.512 0 .375.375 0 1 0 .574-.482 10.86 10.86 0 0 0-5.958-3.623m-7.622-5.51a5.25 5.25 0 1 1 10.5-.001 5.25 5.25 0 0 1-10.5 0m18.21 4.362-.683-.395c.13-.385.13-.801 0-1.186l.684-.395a.375.375 0 0 0-.375-.65l-.685.396A1.87 1.87 0 0 0 21 10.912v-.788a.375.375 0 1 0-.75 0v.788a1.87 1.87 0 0 0-1.026.595l-.685-.395a.375.375 0 0 0-.375.65l.684.394a1.85 1.85 0 0 0 0 1.186l-.684.395a.375.375 0 0 0 .375.65l.685-.396c.269.304.629.513 1.026.595v.788a.375.375 0 1 0 .75 0v-.788a1.87 1.87 0 0 0 1.026-.595l.685.395a.375.375 0 0 0 .375-.65m-2.46.137a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25'
      fill={color}
    />
  </svg>
);
