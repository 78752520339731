import styled from 'styled-components';
import { ProgressBarProps } from './progressBar.types';

export const ProgressBarContainer = styled.div<
  Pick<ProgressBarProps, 'height' | 'backgroundColor' | 'width'>
>`
  width: ${({ width }) => width};
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
  position: relative;
  height: ${({ height }) => height};
  z-index: 0; 
`;

export const Filler = styled.div<
  Pick<ProgressBarProps, 'color'> & { progress: number }
>`
  position: absolute;
  height: 100%;
  width: ${({ progress }) => (progress > 100 ? 100 : progress)}%;
  background-color: ${({ color }) => color};
  transition: width 0.2s ease-in;
  z-index: 1; 
`;

export const BlurFiller = styled.div<
  Pick<ProgressBarProps, 'blurHeight' | 'blurPosition'> & {
    progress: number;
    blur: string;
  }
>`
  position: absolute;
  height: ${({ blurHeight }) => blurHeight};
  width: ${({ progress }) => (progress > 100 ? 100 : progress)}%;
  background: linear-gradient(
    90deg,
    rgba(216, 243, 79, 0) 0%,
    rgba(216, 243, 79, 1) 100%
  );
  top: ${({ blurPosition }) => blurPosition};
  filter: ${({ blur }) => blur};
  transition: width 0.2s ease-in;
  z-index: 2; 
`;
