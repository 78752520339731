import * as React from 'react';
import { IconProps } from '../icon.types';

export const RaitingIcon: React.FC<IconProps> = ({
  color = '#fff',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21.75 6.375h-2.625V5.25A1.126 1.126 0 0 0 18 4.125H6A1.126 1.126 0 0 0 4.875 5.25v1.125H2.238A1.126 1.126 0 0 0 1.113 7.5V9a3.38 3.38 0 0 0 3.375 3.375h.658c.816 2.903 3.392 5.076 6.48 5.24v3.01H9a.375.375 0 1 0 0 .75h6a.375.375 0 0 0 0-.75h-2.625v-3.01a7.13 7.13 0 0 0 6.5-5.24h.625A3.38 3.38 0 0 0 22.875 9V7.5a1.126 1.126 0 0 0-1.125-1.125m-17.262 5.25A2.63 2.63 0 0 1 1.863 9V7.5a.375.375 0 0 1 .375-.375h2.637v3.29q0 .61.103 1.21zm12.003 3.4a6.34 6.34 0 0 1-4.54 1.85c-3.488-.026-6.326-2.924-6.326-6.46V5.25A.375.375 0 0 1 6 4.875h12a.375.375 0 0 1 .375.375v5.25a6.33 6.33 0 0 1-1.884 4.525M22.125 9a2.63 2.63 0 0 1-2.625 2.625h-.464a7 7 0 0 0 .09-1.125V7.125h2.624a.375.375 0 0 1 .375.375z'
      fill={color}
    />
  </svg>
);
