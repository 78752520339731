import { colors } from 'app/colors';
import styled, { css } from 'styled-components';

export const Gradient = styled.div<{
  color?: string;
  isFull?: boolean;
  displayed?: boolean;
}>`
  ${({ color = colors.primaryColor, isFull = true, displayed = true }) => css`
    ${displayed &&
    css`
      background: radial-gradient(
        50% ${!isFull ? '35%' : '50%'} at 50% 50%,
        ${color}6b 30%,
        ${color}45 60%,
        ${color}00 105%
      );
    `}
    border-radius: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`;
