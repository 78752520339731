import { colors } from 'app/colors';
import { Wrapper } from './Logo.styled';
import { LogoProps } from './logo.types';

export const Logo: React.FC<LogoProps> = ({
  fontSize = '24px',
  color = colors.whiteColor,
}) => (
  <Wrapper fontSize={fontSize} color={color}>
    SINT
  </Wrapper>
);

