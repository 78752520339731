import * as React from 'react';
import { IconProps } from '../icon.types';

export const CloseIcon: React.FC<IconProps> = ({
  color = '#ffffff',
  width = 16,    
  height = 16, 
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='none'
    {...props}
  >
    <path
      d="M19.0152 18.4849C19.05 18.5197 19.0776 18.561 19.0965 18.6065C19.1153 18.652 19.125 18.7008 19.125 18.75C19.125 18.7993 19.1153 18.848 19.0964 18.8935C19.0776 18.939 19.05 18.9804 19.0151 19.0152C18.9803 19.05 18.939 19.0776 18.8935 19.0965C18.848 19.1153 18.7992 19.125 18.75 19.125C18.7007 19.125 18.652 19.1153 18.6065 19.0964C18.561 19.0776 18.5196 19.05 18.4848 19.0151L12 12.5304L5.51517 19.0151C5.44486 19.0855 5.34948 19.125 5.25002 19.125C5.15057 19.125 5.05519 19.0855 4.98486 19.0152C4.91452 18.9449 4.87501 18.8495 4.875 18.75C4.87499 18.6506 4.91449 18.5552 4.98481 18.4849L11.4696 12L4.98481 5.51513C4.91449 5.4448 4.87499 5.34941 4.875 5.24996C4.875 5.20071 4.88471 5.15195 4.90356 5.10646C4.92241 5.06096 4.95003 5.01963 4.98486 4.98481C5.01968 4.94999 5.06102 4.92237 5.10652 4.90353C5.15202 4.88469 5.20078 4.875 5.25002 4.875C5.34948 4.87501 5.44486 4.91452 5.51517 4.98486L12 11.4696L18.4848 4.98486C18.5196 4.95003 18.561 4.92241 18.6065 4.90356C18.652 4.88471 18.7007 4.875 18.75 4.875C18.7992 4.875 18.848 4.88469 18.8935 4.90353C18.939 4.92237 18.9803 4.94999 19.0151 4.98481C19.05 5.01963 19.0776 5.06096 19.0964 5.10646C19.1153 5.15195 19.125 5.20071 19.125 5.24996C19.125 5.2992 19.1153 5.34797 19.0965 5.39346C19.0776 5.43896 19.05 5.4803 19.0152 5.51513L12.5303 12L19.0152 18.4849Z"
      fill={color}
    />
  </svg>
);
