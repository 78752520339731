import * as React from 'react';
import { IconProps } from '../icon.types';

export const BorderIcon: React.FC<IconProps> = ({
  color = '#7F64F2',
  width = 4,
  height = 42,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 4 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='none'
    {...props}
  >
    <path
      d='M0 0H4V42H0V32.875L2 31.75V9.25L0 8.125V0Z'
      fill={color}
      style={{ width, height }} // Растягиваем path по всей высоте и ширине SVG
    />
  </svg>
);
