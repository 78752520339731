import { Login } from 'feature/auth/ui/Login';
import type { FC } from 'react';

export const LoginPage: FC = () => {
  return (
    <>
      <Login />
    </>
  );
};
