// Button.tsx
import React, { FC } from 'react';
import { Content, StyledBorderIcon, StyledButton } from './Button.styled';
import { ButtonProps } from './button.types';

export const Button: FC<ButtonProps> = ({
  shape = 'default',
  color,
  borderColor,
  borderSize = 1,
  children,
  decoration,
  size = 'm',
  ...props
}) => (
  <StyledButton
    borderColor={borderColor}
    borderSize={borderSize}
    shape={shape}
    color={color}
    decoration={decoration}
    size={size}
    {...props}
  >
    <StyledBorderIcon
      decoration={decoration}
      color={borderColor || color}
      height={'100%'}
      isFirst
    />
    <Content>{children}</Content>
    <StyledBorderIcon
      color={borderColor || color}
      decoration={decoration}
      height={'100%'}
    />
  </StyledButton>
);
