import { createContext, useContext } from 'react';
import { ToastProps } from './toast.types';

const ToastContext = createContext<ToastProps | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export default ToastContext;
