// src/entities/user/slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store/rootStore';
import { User } from './types';

const initialState: User = {
  query_id: '',
  user: {
    id: 0,
    is_bot: false,
    is_premium: false,
    first_name: '',
    last_name: '',
    username: '',
    language_code: '',
  },
  auth_date: '',
  hash: '',
  startParam: '',
  timezone: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => initialState,
    setUser: (state, action: PayloadAction<User>) => {
      // state.id = action.payload.id;
      // state.isFetched = action.payload.isFetched;
      // state.nickname = action.payload.nickname;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchUser.fulfilled, (state, action) => {
    //   state.id = action.payload.id;
    //   state.nickname = action.payload.nickname;
    //   state.isFetched = true;
    // });
  },
});

// Экспорт действий для использования в других частях приложения
export const { clearUser, setUser } = userSlice.actions;

// Селектор для получения состояния пользователя
export const selectUser = (state: RootState) => state.user;

// Экспорт редьюсера для добавления в rootReducer
export default userSlice.reducer;
