import * as React from 'react';
import { IconProps } from '../icon.types';
import { colors } from 'app/colors';

interface DivDecorationProps extends IconProps {
  isReverse?: boolean;
}

export const DivDecoration: React.FC<DivDecorationProps> = ({
  color = colors.whiteColor,
  width = 5,
  height = 34,
  isReverse = false,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 5 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='none'
    style={{
      transform: isReverse ? 'rotate(180deg)' : 'none',
      display: 'block',
    }}
    {...props}
  >
    <path
      d='M4.06662 0L4.5 0.310264L1.05789 4.06005V5.64669L1.89227 7.03491V16.7559H1.33437V7.15756L0.500002 5.76934V3.88543L4.06662 0Z'
      fill={color}
      fill-opacity='0.25'
    />
    <path
      d='M4.06662 34L4.5 33.6897L1.05789 29.94V28.3533L1.89226 26.9651V16.3604H1.33437V26.8424L0.5 28.2307V30.1146L4.06662 34Z'
      fill={color}
      fill-opacity='0.25'
    />
  </svg>
);
