import React, { ReactNode } from 'react';

import { LayoutProps } from './layout.types';

import { Background, Section, Span, StyledLayout } from './Layout.styled';

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Background>
      <Section>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
        <Span></Span>
      </Section>
      <StyledLayout>{children}</StyledLayout>
    </Background>
  );
};
