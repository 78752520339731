import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 10px;
  position: absolute;
  width: 100vw;
  display: flex;
  bottom: 0;
  font-size: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const Item = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  letter-spacing: 0.12em;
  color: ${({ isActive }) =>
    isActive ? 'var(--white-color)' : 'var(--text-inactive-color)'};
  align-items: center;
`;

export const StyledIcon = styled.svg<{ isActive: boolean }>`
  path {
    fill: ${({ isActive }) =>
      isActive ? 'var(--white-color)' : 'var(--text-inactive-color)'};
  }
`;
