import { useState } from 'react';
import { useInterval } from 'react-use';
import { Counter } from 'shared/counter';
import { CircleBg, Content, Row, Title, Wrapper } from './Balance.styled';
import { LotusIcon } from 'shared/icon';
import { colors } from 'app/colors';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

export const Balance = () => {
  const [counterValue, setCounterValue] = useState(200);

  // Увеличиваем значение счетчика на 11 каждые 3 секунды
  useInterval(() => {
    setCounterValue((prevValue) => prevValue + 11);
  }, 3000);

  return (
    <Wrapper>
      <Title> your balance</Title>
      <Content>
        <DivDecoration height={'100%'} />
        <Row>
          <Counter value={counterValue} />
          <CircleBg>
            <LotusIcon color={colors.blackColor} size='15' />
          </CircleBg>
        </Row>
        <DivDecoration isReverse height={'100%'} />
      </Content>
    </Wrapper>
  );
};
