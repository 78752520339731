import React, { useState } from 'react';
import { ModalProps } from './modal.types';
import { Modal } from './Modal'; 
import { ModalContext } from './ModalContext';
import { useLockBodyScroll } from 'react-use';


export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  const openModal = (props: ModalProps) => {
    setModalProps(props);
  };

  const closeModal = () => {
    setModalProps(null);
  };

  useLockBodyScroll(modalProps !== null);

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modalProps }}>
      {children}
      {modalProps && <Modal {...modalProps} onRequestClose={closeModal} />}
    </ModalContext.Provider>
  );
};
