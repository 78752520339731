// ProgressBar.tsx
import { FC, useMemo } from 'react';
import { colors } from 'app/colors';
import { ProgressBarContainer, Filler, BlurFiller } from './ProgressBar.styled';
import { ProgressBarProps } from './progressBar.types';

export const ProgressBar: FC<ProgressBarProps> = ({
  current,                    
  target, 
  backgroundColor = colors.progressBgColor,  
  color = colors.fillBarColor,               
  height = '1px',
  width = '100%',  
  blurHeight = '3px',
  blur = 'blur(4px)',
  blurPosition = '-2px'      
}) => {

  const progress = useMemo(() => {
    return (current / target) * 100;
  }, [current, target]); 

  return (
    <div style={{ position: 'relative', width }}>
      <ProgressBarContainer 
        height={height} 
        backgroundColor={backgroundColor}
        width={width}
      >
          <Filler 
            progress={progress} 
            color={color} 
          />
        </ProgressBarContainer>
      <BlurFiller 
        progress={progress} 
        color={color} 
        blurHeight={blurHeight} 
        blur={blur} 
        blurPosition={blurPosition} 
      />
    </div>
  );
};
