import { colors } from 'app/colors';
import { RobotScene } from 'feature/3d';
import { useState } from 'react';
import { useInterval } from 'react-use';
import type { FC } from 'react';
import { Button } from 'shared/button';
import { Counter } from 'shared/counter';
import { Gradient } from 'shared/gradient';
import { useModal } from 'shared/modal';
import { useToast } from 'shared/toast/ToastContext';
import { ProgressBar } from 'shared/progress';
import { Balance } from 'feature/balance';

export const SandBox: FC = () => {
  const { openModal } = useModal();
  const { showToast } = useToast();

  const handleSuccess = () => {
    showToast('This is a success message!', 'success');
  };

  const handleError = () => {
    showToast('This is an error message!', 'error');
  };

  const handleWarning = () => {
    showToast('This is a warning message!', 'warn');
  };

  const handleOpenModal = () => {
    openModal({
      isOpen: true,
      onRequestClose: () => console.log('Modal closed'),
      children: (
        <div>
          <p>test</p>
        </div>
      ),
    });
  };

  return (
    <div>
      <div style={{ background: '' }}>
        <br />
        <Button borderColor={colors.primaryColor} size='l'>
          <Gradient color={colors.primaryColor}>Button l</Gradient>
        </Button>
        <br />
        <Button borderColor={colors.secondaryColor} decoration='basic' size='m'>
          Button m
        </Button>
        <br />
        <Button
          borderColor={colors.secondaryColor}
          decoration='reverse'
          size='m'
        >
          <Gradient color={colors.secondaryColor}>Button m</Gradient>
        </Button>
        <br />
        <Button
          borderColor={colors.whiteColor}
          color={colors.whiteColor}
          decoration='reverse'
          size='m'
        >
          <div style={{ color: 'black' }}>Check</div>
        </Button>
        <br />
        <Button borderColor={colors.primaryColor} size='s'>
          Button s
        </Button>
        <br />
        <Button size='l' borderColor={colors.primaryColor} shape='round'>
          1
        </Button>
        <Button size='m' borderColor={colors.secondaryColor} shape='round'>
          <Gradient color={colors.secondaryColor}>2</Gradient>
        </Button>
        <Button size='s' borderColor={colors.primaryColor} shape='round'>
          <Gradient color={colors.primaryColor}>3</Gradient>
        </Button>
        <br />

        <Button size='m' shape='round'>
          <Gradient isFull={false} color={colors.primaryColor}>
            ico
          </Gradient>
        </Button>
        <br />
        <div>
          <Button
            onClick={handleOpenModal}
            borderColor={colors.primaryColor}
            size='l'
          >
            <Gradient color={colors.primaryColor}>open modal</Gradient>
          </Button>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleSuccess}
          >
            Success
          </Button>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleError}
          >
            Error
          </Button>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleWarning}
          >
            Warning
          </Button>
        </div>
        <ProgressBar
          current={70}
          target={100}
          backgroundColor={colors.progressBgColor}
          color={colors.fillBarColor}
        />
        <br />
        <Balance />
      </div>
      <RobotScene />
    </div>
  );
};
