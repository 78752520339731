import styled from 'styled-components';

export const StyledToast = styled.div`
  & .Toastify__toast-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  & .Toastify__toast {
    border-radius: 22px;
    width: 90%;
    margin: 0 auto;
  }

  & .Toastify__toast--success {
    background-color: #28a745;
  }

  & .Toastify__toast--error {
    background-color: #dc3545;
  }

  & .Toastify__toast--warning {
    background-color: #d8f34f;
  }
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`