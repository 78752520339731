import React, { createContext, useContext, useEffect, useState } from 'react';
import { TelegramUser, useTelegramAuth } from 'feature/auth';
import { AuthContextProps } from './AuthProvoider.types';
import { useLoginMutation, useRegisterMutation } from 'entities/user/api';

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    user: telegramUser,
    isReady,
    initData,
    startParams,
  } = useTelegramAuth(); // Получаем данные пользователя из Telegram
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();

  const [user, setUser] = useState<TelegramUser | null>(null);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (telegramUser) {
      // Если получили данные из Telegram, пытаемся авторизовать или зарегистрировать пользователя
      handleLoginOrRegister();
    } else {
      setUser(null);
      setAuthenticated(false); // Если нет данных из Telegram, сбрасываем состояние
    }
  }, [telegramUser]);

  const handleLoginOrRegister = async () => {
    try {
      // Пробуем выполнить логин с данными из Telegram
      const result = await login({ data: initData }).unwrap();
      setUser(result); // Если логин успешен, сохраняем данные пользователя
      setAuthenticated(true);
    } catch (loginError) {
      console.error('Login failed, attempting registration', loginError);

      // Если логин не удался, пробуем зарегистрировать пользователя
      try {
        const result = await register({ data: initData }).unwrap();
        setUser(result); // Если регистрация успешна, сохраняем данные пользователя
        setAuthenticated(true); // Устанавливаем статус авторизованного пользователя
      } catch (registerError) {
        console.error('Registration failed', registerError);

        setAuthenticated(false); // Если регистрация не удалась, статус остается неавторизованным
      }
    }
  };

  const logout = () => {
    setUser(null);
    setAuthenticated(false); // Сбрасываем состояние при выходе
  };

  return (
    <AuthContext.Provider
      value={{
        isTelegramReady: isReady,
        isAuthenticated,
        user,
        tgUser: telegramUser,
        login: handleLoginOrRegister,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
