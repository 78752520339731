import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Model } from '../model/3dModel';

export function RobotScene() {
  return (
    <>
      <Canvas
        style={{ width: '100vw', height: '100vh', background: 'transparent' }}
        camera={{ position: [0, 0, 5], fov: 30 }}
      >
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} />
        <Model />
        <OrbitControls
          rotateSpeed={1}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          minDistance={10}
          maxDistance={10}
        />
      </Canvas>
    </>
  );
}
