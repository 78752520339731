import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'react-use';
import { ROUTES } from 'app';

import { use3dModelContext } from 'feature/3d';
import { Content, LogoWrapper, Wrapper } from './Login.styled';

import { useAuth } from '../model/authProvoider/useAuth';
import { ProgressBar } from 'shared/progress';
import { Logo } from 'shared/logo';

// const isDev = false;
const isDev = process.env.NODE_ENV === 'development';

const FAKE_PROGRESS_DURATION_MS = 6000;

// Интервал обновления прогресса (в миллисекундах)
const PROGRESS_INTERVAL_MS = 100;

const MemoizedProgressBar = memo(ProgressBar);

export const Login = () => {
  const { isAuthenticated, login, isTelegramReady } = useAuth();
  const { isLoading } = use3dModelContext();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  // Расчет инкремента прогресса для достижения 100% за FAKE_PROGRESS_DURATION_MS
  const progressIncrementSlow = useMemo(
    () => (100 / FAKE_PROGRESS_DURATION_MS) * PROGRESS_INTERVAL_MS * 0.5,
    []
  ); // Мемоизируем, так как значения FAKE_PROGRESS_DURATION_MS и PROGRESS_INTERVAL_MS постоянны

  const progressIncrementFast = useMemo(
    () => (100 / FAKE_PROGRESS_DURATION_MS) * PROGRESS_INTERVAL_MS,
    []
  );

  useInterval(() => {
    setProgress((prev) => {
      if (prev < 30) {
        return Math.min(prev + progressIncrementSlow, 100);
      }
      return Math.min(prev + progressIncrementFast, 100);
    });
  }, PROGRESS_INTERVAL_MS);

  useEffect(() => {
    if (isTelegramReady) {
      login();
    }
  }, [isTelegramReady]);

  useEffect(() => {
    if ((isAuthenticated && !isLoading) || (isDev && !isLoading)) {
      navigate(ROUTES.GAME, { replace: true });
    }
  }, [isAuthenticated, isLoading, navigate, isTelegramReady]);

  return (
    <Wrapper>
      <Content>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {/* <progress style={{ width: '100%' }} value={progress / 100} /> */}
        <MemoizedProgressBar current={progress} target={100} />
      </Content>
    </Wrapper>
  );
};
