import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;
export const Content = styled.div`
  padding: 0 20px;
  padding-top: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 27px;
`;
