import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

const GlobalStyle = createGlobalStyle`
  /* Сброс базовых стилей */
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Titillium Web', sans-serif;  /* Используйте шрифт через глобальные стили */
    color: #333;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Стили для ссылок */
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /* Убираем стандартные стили списка */
  ul, ol {
    list-style: none;
  }

  /* Настройка изображений */
  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  /* Убираем стили из таблиц */
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* Кнопки и интерактивные элементы */
  button, input, textarea, select {
    font: inherit;
    color: inherit;
    background: none;
    border: none;
    outline: none;
  }

  /* Базовые стили для заголовков */
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: #222;
  }
  
  /* CSS-переменные для работы с цветами */
  :root {
    --primary-color: ${colors.primaryColor};
    --secondary-color: ${colors.secondaryColor};
    --tertiary-color: ${colors.tertiaryColor};
    --text-inactive-color: ${colors.textInactiveColor};
    --black-color: ${colors.blackColor};
    --white-color: ${colors.whiteColor};
    --gradient: ${colors.gradient};
  }
`;

export default GlobalStyle;
